






import { AsyncComponent } from 'vue'
import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'

import { CTABoxModule } from '../CTABox.contracts'
import { logger } from '../../../support'
import { CTABoxModuleVersion, ctaBoxModuleVersionsRegistry } from '../CTABox.config'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component({
  name: 'CTABoxModuleUi',
})
export class CTABoxModuleUi extends AbstractModuleUi<CTABoxModule> {
  /**
   * Determines component version.
   */
  public get component (): AsyncComponent | undefined {
    const component = ctaBoxModuleVersionsRegistry[this.version]

    if (typeof component === 'undefined') {
      logger(
        `CTABoxModuleUi.ui.component(): There's no Vue component associated with the [${this.data.version}] CTABoxModuleUi!`,
        'error'
      )
      return
    }

    return component
  }

  public get version () {
    if (!this.data.version) {
      return CTABoxModuleVersion.Default
    }

    return this.data.version as CTABoxModuleVersion
  }
}

export default CTABoxModuleUi
